import React from 'react'
import SetNewPassword from 'domains/Affiliate/SetNewPassword'
import Layout from 'components/Layout'
import SEO from 'components/seo'

const SetPasswordPage = (props) => (
  <>
    <SEO title="Set New Password" />
    <Layout>
      <SetNewPassword { ...props } />
    </Layout>
  </>
)

export default SetPasswordPage
