import styled, { css } from 'styled-components'
import { darkBlueGrey } from 'components/baseColor'
import { IS_FRAME_MOBILE } from 'utils'

export const WrapperPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 35% 8rem;

  ${({ isTextPage }) => isTextPage &&
    css`
      background-color: #fff;
      background-image: url("/eye-illustration.png");
      background-repeat: no-repeat;
      background-size: 93%;
      background-position: center 30%;
    `}
`

export const Form = styled.form`
  padding: 3em;
  overflow: hidden;
  background-color: #fff;
`

export const Title = styled.div`
  font-size: 1.7em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.43px;
  color: ${darkBlueGrey};

  @media only screen and (max-width: 600px) {
    font-size: 1.5em;
    text-align: center;
  }
`

export const Desc = styled.div`
  font-size: 1.1em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  color: ${darkBlueGrey};
  margin-bottom: 2.5em;

  ${IS_FRAME_MOBILE} {
    text-align: center;
    font-size: 0.9rem !important;
  }
`

export const Gap = styled.div`
  margin-bottom: 2em;
`

export const Img = styled.img`
  display: block;
  margin: auto;
  width: 60%;

  ${IS_FRAME_MOBILE} {
    width: 70%;
  }
`
