/* eslint-disable react/jsx-closing-bracket-location */
import React, { useState } from 'react'
import { navigate } from 'gatsby'
import qs from 'query-string'
import { useIntl } from 'react-intl'
import { InputText, Button } from 'components'
import ActivationView from 'domains/Affiliate/Activation'
import { WrapperPage, Gap, Form, Title, Desc, Img } from './styles'
import API, { AffiliateAuthURL } from 'commons/API'
import useCheckToken from './models/useCheckToken'
import { isBrowser } from 'utils'
import { useToasts } from 'react-toast-notifications'
import QlueUnityDashboardIcon from 'media/images/affiliate-dashboard/qlueunity-dashboard-icon.png'
import { generateElementID, SET_NEW_PASSWORD_ID_PREFIX } from 'utils/generateElementID'

const SetPassword = (props) => {
  const queryParams = props.location.search
  const { t: tokenUser, modePage, ...queryParamsData } = qs.parse(queryParams)

  const intl = useIntl()

  const [form, setForm] = useState({ pass: '', confirmPass: '' })
  const [errors, setErrors] = useState({ pass: '', confirmPass: '' })
  const [isLoading, setloading] = useState(false)
  const checkToken = useCheckToken({
    tokenUser,
    modePage,
    redirectToExpiredTokenPage
  })

  const { addToast } = useToasts()

  function redirectToExpiredTokenPage() {
    const params = qs.stringify({
      next_link : queryParams.next_link,
      modePage  : 'link_expired',
      t         : tokenUser
    })

    navigate(`${location.pathname}?${params}`)
  }

  const checkErrors = () => {
    (function clearErrors() {
      for (const [key] of Object.entries(errors)) {
        setErrors((prevErrs) => ({ ...prevErrs, [key]: '' }))
      }
    })()

    let hasError = false

    for (const [key, val] of Object.entries(form)) {
      if (!val) {
        hasError = true
        setErrors((prevErrs) => ({ ...prevErrs, [key]: 'f92278b9e' }))
      }
      else {
        if (form.pass !== form.confirmPass) {
          hasError = true
          setErrors((prevErrs) => ({ ...prevErrs, confirmPass: 'b8b3b3aa5' }))
        }
      }
    }

    return hasError
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setloading(true)

    try {
      if (!checkErrors()) {
        const { data } = await API({
          url     : AffiliateAuthURL.PostActivationAffiliate,
          method  : 'post',
          headers : { authorization: `Bearer ${tokenUser}` },
          data    : {
            password              : form.pass,
            password_confirmation : form.confirmPass
          }
        })
        const params = qs.stringify({
          ...queryParamsData,
          t        : tokenUser,
          modePage : 'success',
          email    : data.affiliate_user.email
        })
        navigate(`/affiliate/successfully-set-new-password/?${params}`)
      }
    } catch (err) {
      if (err?.response) {
        if (err.response.status === 403) redirectToExpiredTokenPage()
        else setErrors((prevErrs) => ({ ...prevErrs, confirmPass: err.response.data.message }))
      } else addToast(err.message, { appearance: 'warning' })
    }

    setloading(false)
  }

  if (!tokenUser && isBrowser) {
    navigate('/')
    return null
  }

  const handleChange = (type) => (e) => {
    const { value } = e.target
    setForm((prevForm) => ({ ...prevForm, [type]: value }))
  }

  return (
    <WrapperPage isTextPage={ modePage }>
      { modePage && modePage !== 'success' && (
        <ActivationView tokenUser={ tokenUser } type={ modePage } queryParams={ queryParamsData } />
      ) }
      { !modePage && (
        <>
          <Img 
            src={ QlueUnityDashboardIcon } 
            alt='QlueUnity'
          />
          <Form autocomplete="off" onSubmit={ handleSubmit }>
            <Title>Set New Password</Title>
            <Desc>Set your new password to user for login</Desc>
            <InputText
              value={ form.pass }
              onChange={ (e) => handleChange('pass')(e) }
              type="password"
              name="pass"
              title={ intl.formatMessage({ id: '13c7d3bb2' }) }
              error={ errors.pass && intl.formatMessage({ id: errors.pass }) }
              placeholder={ intl.formatMessage({ id: '6fc7b06e2' }) }
              style={{ width: '18rem' }}
              id={ generateElementID(SET_NEW_PASSWORD_ID_PREFIX, 'password') }
            />
            <Gap />
            <InputText
              value={ form.confirmPass }
              onChange={ (e) => handleChange('confirmPass')(e) }
              error={ errors.confirmPass && intl.formatMessage({ id: errors.confirmPass }) }
              type="password"
              name="confirmPassword"
              title={ intl.formatMessage({ id: '98d04fe7e' }) }
              placeholder={ intl.formatMessage({ id: 'fbaf29ae0' }) }
              style={{ width: '18rem' }}
              id={ generateElementID(SET_NEW_PASSWORD_ID_PREFIX, 'confirmpassword') }
            />
            <Gap />
            <Button
              isLoading={ isLoading }
              disabled={ checkToken.isLoading }
              type="submit"
              width="100%"
              buttonType="warning"
              id={ generateElementID(SET_NEW_PASSWORD_ID_PREFIX, 'btnsetnewpassword') }
            >
              { checkToken.isLoading
                ? intl.formatMessage({ id: '2b8b2a586' })
                : 'SET NEW PASSWORD' } 
            </Button>
          </Form>
        </>
      ) }
    </WrapperPage>
  )
}

export default SetPassword
