import { useState, useRef, useEffect } from 'react'
import API, { AffiliateAuthURL } from 'commons/API'

const useCheckToken = ({ tokenUser, modePage, redirectToExpiredTokenPage }) => {
  const [isLoading, setLoading] = useState(false)
  const wasCalledOnce = useRef(false)

  useEffect(() => {
    if (tokenUser && !modePage && !wasCalledOnce.current) {
      wasCalledOnce.current = true;
      (async function checkToken() {
        setLoading(true)

        try {
          await API({
            url     : AffiliateAuthURL.GetCheckExpiredAffiliate,
            headers : {
              authorization: `Bearer ${tokenUser}`
            }
          })
        } catch (err) {
          redirectToExpiredTokenPage()
        }

        setLoading(false)
      })()
    }
  }, [modePage, redirectToExpiredTokenPage, tokenUser])

  return { isLoading }
}

export default useCheckToken
